import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import * as SearchActions from '@@shared/stores/search-store/actions/search.actions';
import { selectSearchByKey } from '@@shared/stores/search-store/selectors/search.selectors';



@Injectable({
	providedIn: 'root'
})
export class SearchService {
	readonly #search = new BehaviorSubject('');
	readonly #store = inject(Store);

	// single search in page - old way

	get search$(): Observable<string> {
		return this.#search.asObservable();
	}

	searchChanged(text: string): void {
		this.#search.next(text);
	}

	clearSearch(): void {
		this.#search.next('');
	}

	// multi search in page - new way (implemented in BaseWithListMultiSearchComponent)

	getMultiSearch(id: string): Observable<string> {
		return this.#store.select(selectSearchByKey(id));
	}

	multiSearchChanged(id: string, text: string): void {
		this.#updateSearch(id, text);
	}

	multiClearSearch(id: string): void {
		this.#updateSearch(id, '');
	}

	#updateSearch(id: string, text: string): void {
		this.#store.dispatch(SearchActions.updateSearchId({ id, searchTerm: text }));
	}
}
