import { createSelector } from '@ngrx/store';

import { SearchState } from '../reducers/search.reducers';

export const selectSearchState = (state) => state.searchStore;

export const selectSearchByKey = (id: string) => createSelector(
	selectSearchState,
	(state: SearchState) => state.entities[id]?.searchTerm
);
